<template>
    <div class="main">

        <agent-info-comp></agent-info-comp>

        <agent-sub-title>통계표</agent-sub-title>

        <div class="search">
            <partner-sub-selector-comp @setPartnerId="setPartnerId"></partner-sub-selector-comp>
            <date-selector-comp @setStartDate="setStartDate" @setEndDate="setEndDate"></date-selector-comp>
            <el-button type="primary" size="mini" style="margin-left: 5px" @click="search">검색</el-button>
        </div>
        <div class="data">
            <table class="agent_table">
                <tr>
                    <th>일짜</th>
                    <th>신규회원</th>
                    <th>블럭회원</th>
                    <th>실베터</th>
                    <th>첫충P</th>
                    <th>매충P</th>
                    <th>입금</th>
                    <th>출금</th>
                    <th>파트너출금</th>
                    <th>손이익</th>
                    <th>정산금액</th>
                </tr>
                <tr v-for="item in sdata">
                    <td>
                        <span v-if="item.normal == agentConst.YES">{{item.date|datef('MM-DD')}}</span>
                        <span v-if="item.normal == agentConst.NOT"
                              class="text-blue text-blod">{{item.date}}</span>
                    </td>
                    <td>
                        <span :class="{'totalText':item.normal === agentConst.NOT}">{{item.siteMemberCount|comma}} 명</span>
                    </td>
                    <td>
                        <span :class="{'totalText':item.normal === agentConst.NOT}">{{item.siteBlockMemberCount|comma}} 명</span>
                    </td>
                    <td>
                        <span :class="{'totalText':item.normal === agentConst.NOT}"> {{item.realBeter|comma}} 명</span>
                    </td>
                    <td>
                        <span :class="{'totalText':item.normal === agentConst.NOT}">{{item.accumulateFirstRechargePoint|comma}}</span>
                    </td>
                    <td>
                        <span :class="{'totalText':item.normal === agentConst.NOT}">{{item.accumulateDayRechargePoint|comma}}</span>
                    </td>
                    <td>
                        <span :class="{'totalText':item.normal === agentConst.NOT}"> {{item.rechargeTotal|comma}}</span>
                    </td>
                    <td>
                        <span :class="{'totalText':item.normal === agentConst.NOT}">{{item.exchangeTotal|comma}}</span>
                    </td>
                    <td>
                        <span :class="{'totalText':item.normal === agentConst.NOT}">{{item.exchangePartnerTotal|comma}}</span>
                    </td>
                    <td>
                        <span :class="{'totalText':item.normal === agentConst.NOT}">
                            <span :class="{'text-red':(item.rechargeTotal - item.exchangeTotal) < 0}">{{(item.rechargeTotal - item.exchangeTotal - item.exchangePartnerTotal)|comma}}</span>
                        </span>
                    </td>
                    <td>
                         <span :class="{'totalText':item.normal === agentConst.NOT}">
                            <span :class="{'text-red':(item.partnerStatisticAmount) < 0}">
                            {{(item.partnerStatisticAmount)|comma}}
                            </span>
                        </span>
                    </td>
                </tr>

            </table>
        </div>
    </div>
</template>

<script>

    import {Loading} from 'element-ui';
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import AgentSubTitle from "../../components/agent/AgentSubTitle";
    import PartnerSubSelectorComp from "../../components/agent/PartnerSubSelectorComp";
    import {agentMixin} from "../../common/agent/agentMixin";
    import {agentMainStatistic} from "../../network/agent/commonRequest";
    import agentConst from "../../common/agent/agentConst";
    import AgentInfoComp from "../../components/agent/AgentInfoComp";

    export default {
        name: "AgentMainStatistic",
        components: {
            AgentInfoComp,
            PartnerSubSelectorComp,
            AgentSubTitle,
            DateSelectorComp,
        },
        mixins: [agentMixin],
        data() {
            return {
                agent: {id:null},
                sdata: [],
                months: [
                    {'key': '1월', 'val': '1'}, {'key': '2월', 'val': '2'}, {'key': '3월', 'val': '3'}, {
                        'key': '4월',
                        'val': '4'
                    }, {'key': '5월', 'val': '5'}, {'key': '6월', 'val': '6'},
                    {'key': '7월', 'val': '7'}, {'key': '8월', 'val': '8'}, {'key': '9월', 'val': '9'}, {
                        'key': '10월',
                        'val': '10'
                    }, {'key': '11월', 'val': '11'}, {'key': '12월', 'val': '12'}
                ],
                accumulateBetFailPoint: 0,//낙첨포인트
                accumulateDayRechargePoint: 0,//매충포인트
                accumulateFirstRechargePoint: 0,//첫충포인트
                accumulateManagerAddCash: 0,//수동지급 머니
                accumulateManagerAddPoint: 0,//수동지급 포인트
                exchangePartnerTotal: 0,//총판출금합계
                exchangeTotal: 0,//출금합계
                rechargeTotal: 0,//입금합계
                partnerStatisticAmount: 0,// 정산금액(입금 - 출금  * 프로수)
                siteMemberCount: 0,//신규가입
                siteBlockMemberCount: 0,//신규가입
                realBeter: 0,//실베터
            }
        }
        ,
        methods: {
            getMainStatistic() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                agentMainStatistic(this.agent, this.startTime, this.endTime).then(res => {
                    this.accumulateBetFailPoint = 0;
                    this.accumulateDayRechargePoint = 0;
                    this.accumulateFirstRechargePoint = 0;
                    this.accumulateManagerAddCash = 0;
                    this.accumulateManagerAddPoint = 0;
                    this.exchangeTotal = 0;
                    this.rechargeTotal = 0;
                    this.siteMemberCount = 0;
                    this.siteBlockMemberCount = 0;
                    this.realBeter = 0;
                    this.partnerStatisticAmount = 0;
                    this.exchangePartnerTotal = 0;
                    this.sdata = res.data.data
                    if(this.sdata){
                        this.setTotal(this.sdata)
                    }
                    loadingInstance.close();
                })
            },
            search() {
                this.getMainStatistic()
            },
            setPartnerId(partnerId) {
                this.agent.id = partnerId;
                this.getMainStatistic()
            },
            setStartDate(date) {
                this.startTime = date
            },
            setEndDate(date) {
                this.endTime = date
            },
            setTotal(list) {
                list.map(s => {
                    s.normal = agentConst.YES
                    this.accumulateBetFailPoint += s.accumulateBetFailPoint;
                    this.accumulateDayRechargePoint += s.accumulateDayRechargePoint;
                    this.accumulateFirstRechargePoint += s.accumulateFirstRechargePoint;
                    this.accumulateManagerAddCash += s.accumulateManagerAddCash;
                    this.accumulateManagerAddPoint += s.accumulateManagerAddPoint;
                    this.exchangeTotal += s.exchangeTotal;
                    this.exchangePartnerTotal += s.exchangePartnerTotal;
                    this.rechargeTotal += s.rechargeTotal;
                    this.siteMemberCount += s.siteMemberCount;
                    this.siteBlockMemberCount += s.siteBlockMemberCount;
                    this.realBeter += s.realBeter;
                    this.partnerStatisticAmount += s.partnerStatisticAmount;
                })
                let data = {}
                data.date = '총합계'
                data.accumulateBetFailPoint = this.accumulateBetFailPoint
                data.accumulateDayRechargePoint = this.accumulateDayRechargePoint
                data.accumulateFirstRechargePoint = this.accumulateFirstRechargePoint
                data.accumulateManagerAddCash = this.accumulateManagerAddCash
                data.accumulateManagerAddPoint = this.accumulateManagerAddPoint
                data.exchangePartnerTotal = this.exchangePartnerTotal
                data.exchangeTotal = this.exchangeTotal
                data.rechargeTotal = this.rechargeTotal
                data.siteMemberCount = this.siteMemberCount
                data.siteBlockMemberCount = this.siteBlockMemberCount
                data.realBeter = this.realBeter
                data.partnerStatisticAmount = this.partnerStatisticAmount
                data.normal = agentConst.NOT
                this.sdata.push(data)

            },
        },
        created() {
            this.getMainStatistic();
        }
    }
</script>

<style scoped>
    .table50 {
        border: 2px solid #0c0e0e;
        margin-top: 5px;
        font-weight: 600;
    }



    .totalText {
        font-size: 12px;
        font-weight: bold;
        font-style: italic;
        color: #3a8ee6;
    }

</style>